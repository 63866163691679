import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import useTranslation from 'sholdi-hooks/useTranslation'

import ArrowLeft from 'sholdi-icons/ArrowLeft'
import Flex from 'sholdi-primitives/atoms/Flex'
import Image from 'sholdi-primitives/atoms/Image'
import Text from 'sholdi-primitives/atoms/Text'
import Button from 'sholdi-primitives/atoms/Button'
import Link from 'sholdi-primitives/atoms/Link'
import { twMerge } from 'tailwind-merge'

export default function ErrorPageTemplate({
  message,
  code = 400,
  layout = 'Main',
  apiIsDown,
}) {
  const router = useRouter()
  const { t } = useTranslation()
  const errorCode = code.toString()

  const isMainLayout = layout === 'Main'
  const href = layout === 'SuperAdmin' ? '/superadmin' : isMainLayout ? '/' : '/admin'

  return (
    <Flex
      className={twMerge(
        'min-h-[600px] h-screen w-full flex-col lg:flex-row justify-center items-center',
        !isMainLayout ? 'bg-white rounded-default shadow-md' : 'bg-unset shadow-none',
      )}
    >
      <Flex className="w-full h-1/2 lg:w-1/2 flex flex-col px-8 md:px-24 pb-8 justify-center items-center">
        <Flex className="flex">
          <Text variant="h1" className="text-7xl font-bold">
            {errorCode[0]}
          </Text>
          <Flex
            className={twMerge(
              'w-14 h-14 rounded-full mt-2 mx-1.5',
              isMainLayout ? 'bg-secondary-main' : 'bg-gray-300',
            )}
          />
          <Text variant="h1" className="text-7xl font-bold">
            {errorCode[2]}
          </Text>
        </Flex>
        <Text className="text-xl my-4">{message}</Text>
        {!apiIsDown && (
          <Flex className="w-full mt-8 justify-center">
            <Button
              variant="whiteGray"
              className="text-gray-700 font-medium"
              icon={ArrowLeft}
              buttonSize="lg"
              onClick={() => router.back()}
            >
              {t('back')}
            </Button>
            <Button
              as={Link}
              href={href}
              variant="primary"
              buttonSize="lg"
              className="ml-4"
            >
              {t('index')}
            </Button>
          </Flex>
        )}
      </Flex>
      {isMainLayout && (
        <Image
          src="/assets/images/ErrorPageImage.png"
          alt="error_image"
          layout="fill"
          className={twMerge(
            'w-full hidden lg:block relative h-full md:h-1/2',
            !isMainLayout ? 'lg:hidden' : '',
          )}
        />
      )}
    </Flex>
  )
}

ErrorPageTemplate.propTypes = {
  message: PropTypes.string,
  code: PropTypes.number,
  apiIsDown: PropTypes.bool,
  layout: PropTypes.oneOf(['Main', 'Admin', 'SuperAdmin']),
}
